.App {
  text-align: center;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: .12em;
  word-spacing: .16em;
}

.intro-text {
  /* font-family: 'Sacramento', cursive; */
  font-size: 3rem;
  font-weight: bold;
}

.link {
  color: #FAED26;
  transition: ease-in-out .5s;
}

.link:hover {
  color: #746d11;
  transition: 1s;
}

.nav-bar {
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  justify-content: center;
  border-bottom: #FAED26 1px solid;
  margin-bottom: 2rem;
  background-color: #1b141e;
}

.nav-butt {
  font-weight: bold;
  font-size: 1.3rem;
  width: 10rem;
  background-color: inherit;
  border: none;
  color: #FAED26;
  transition: ease-in-out .5s;
}

.nav-butt:hover {
  color: #746d11;
  text-decoration: underline;
  transition: 1s;
}

img {
  border-radius: .2rem;
}

.home-pic {
  position: fixed;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
}

.contact-pic {
  width: 40rem;
  height: auto;
  padding-top: 2rem;
}

.icon {
  width: 2rem;
  height: 3rem;
  border: none;
  box-shadow: none;
  margin-right: 1rem;
}

.email {
  width: 4rem;
  height: 3rem;
  margin-left: 2rem;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5rem;
}

.about-pic {
  width: 40rem;
  height: auto;
  float: left;
  padding: 2rem;
  border: none;
  box-shadow: none;
}

.about-hr {
  border-top: 2px solid #FAED26;
}

.about-text {
  padding: 3rem;
}

.w-100 {
  max-height: auto;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.carousel-item{
  width: 30%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, .9);
  transition: .5s ease-in-out;
  border: 3px solid #FAED26;
  border-radius: .2rem;
  padding: 3rem 2rem 3rem 2rem;
  position: absolute;
  top: 5rem;
}

.carousel-control-prev-icon:hover, .carousel-control-next-icon:hover {
  background-color: #FAED26;
  transition: 1s;
}

.carousel-caption {
  top: 0;
}

.gallery h5 {
  background-color: black;
  border-radius: 5rem;
  width: 60%;
  margin: auto;
}

.gallery .before {
  font-weight: bold;
  background-color: red;
  border-radius: 5rem;
  width: 60%;
  margin: auto;
}

.gallery .after {
  font-weight: bold;
  background-color: green;
  border-radius: 5rem;
  width: 60%;
  margin: auto;
}

.gallery .during {
  font-weight: bold;
  background-color: #FAED26;
  border-radius: 5rem;
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 850px) {
  .nav-bar {
      gap: 1rem;
    }

  .nav-butt {
    width: 3.5rem;
  }

  p {
    font-size: 2.5vw;
  }

  .intro-text {
    font-size: 6vw;
  }

  .contact-pic {
    width: 90%;
  }

  .gallery .before, .gallery .after, .gallery .during, .gallery h5 {
    font-size: 2.5vw;
  }

  .about-pic {
    float: none;
    padding: 0;
    width: 90%;
    max-width: 30rem;
    min-width: 10rem;
    height: auto;
  }
}